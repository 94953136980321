import { gql } from '__generated__';

export const LIST_ENTRIES_QUERY = gql(`
  query listEntries(
    $site: [String] = "default",
    $preferSites: [QueryArgument] = ["default"],
    $unique: Boolean
    $limit: Int,
    $section: [String],
    $type: [String],
    $orderBy: String,
    $relatedToEntries: [EntryRelationCriteriaInput]
    $relatedToCategories: [CategoryRelationCriteriaInput]
  ) {
    entries(
      private: false,
      site: $site,
      preferSites: $preferSites,
      unique: $unique
      relatedToEntries: $relatedToEntries
      relatedToCategories: $relatedToCategories
      limit: $limit
      section: $section
      orderBy: $orderBy,
      type: $type
    ) {
      ...entryId
    }
  }
`);

export const LIST_CATEGORIES_QUERY = gql(`
  query listCategories(
    $site: [String] = "default",
    $preferSites: [QueryArgument] = ["default"],
    $unique: Boolean
    $group: [String],
    $orderBy: String,
    $relatedToEntries: [EntryRelationCriteriaInput] = null
  ) {
    categories(
      site: $site,
      preferSites: $preferSites,
      unique: $unique
      group: $group,
      orderBy: $orderBy,
      relatedToEntries: $relatedToEntries
    ) {
      ...categoryId
    }
  }
`);

export const SEARCH_ENTRIES_QUERY = gql(`
  query searchEntries(
      $site: [String] = "default",
      $preferSites: [QueryArgument] = ["default"],
      $unique: Boolean
      $limit: Int = 12,
      $offset: Int = 0,
      $section: [String],
      $type: [String],
      $search: String,
      $orderBy: String,
      $relatedToCategories: [CategoryRelationCriteriaInput],
      $relatedToEntries: [EntryRelationCriteriaInput],
      $displayEntry: Boolean
      $fixedOrder: Boolean
      $id: [QueryArgument]
    ) {
    entries(
      private: false,
      site: $site,
      preferSites: $preferSites,
      unique: $unique
      limit: $limit
      offset: $offset
      section: $section
      type: $type
      search: $search
      orderBy: $orderBy
      relatedToCategories: $relatedToCategories
      relatedToEntries: $relatedToEntries
      displayEntry: $displayEntry
      fixedOrder: $fixedOrder
      id: $id
    ) {
      ...entryCards
    }
    entryCount(
      private: false,
      site: $site,
      preferSites: $preferSites,
      unique: $unique
      section: $section
      type: $type
      search: $search
      relatedToCategories: $relatedToCategories
      relatedToEntries: $relatedToEntries
      displayEntry: $displayEntry
      id: $id
    )
  }
`);
